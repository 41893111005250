<template>
  <div class="">
          <div class="flex items-center h-full fixed right-0 z-10">
          <button @click="toggle" class="bg-AbnaYousef w-6 md:w-10 py-1 text-white font-extrabold text-lg md:text-3xl rounded-l-xl flex justify-center items-center focus:outline-none">
              +
          </button>
      </div>
    <section v-if="info" id="info" class="bg-AbnaYousef-bg inset-0 absolute z-50 overflow-y-scroll">
      <div class="max-w-8xl mx-auto py-12 lg:py-20 px-6 md:px-8 lg:px-12 xl:px-0">
        <button @click="toggle" class="w-6 h-6 rounded-full left-0 focus:outline-none">
          <svg :class="`${$i18n.locale}` == 'ar' ? '' : 'transform rotate-180'" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 490.667 490.667" style="enable-background:new 0 0 490.667 490.667;" xml:space="preserve">
          <path style="fill:white;" d="M245.333,0C109.839,0,0,109.839,0,245.333s109.839,245.333,245.333,245.333
            s245.333-109.839,245.333-245.333C490.514,109.903,380.764,0.153,245.333,0z"/>
          <path style="fill:#322d31;" d="M290.133,104.533c-19.139-18.289-49.277-18.289-68.416,0c-18.893,18.881-18.902,49.503-0.021,68.395
            c0.007,0.007,0.014,0.014,0.021,0.021l19.2,19.2H117.333C87.878,192.149,64,216.027,64,245.483
            c0,29.455,23.878,53.333,53.333,53.333h123.584l-19.2,19.2c-17.522,20.175-15.371,50.734,4.804,68.257
            c18.259,15.858,45.423,15.799,63.612-0.139l118.251-118.251c12.492-12.496,12.492-32.752,0-45.248L290.133,104.533z"/>
          </svg>
        </button>
        <div class="mt-6 md:mt-10 lg:mt-16 lg:flex">
          <div class="lg:w-3/12 text-white text-lg">
            <div class="fontBold  text-2xl md:mb-2">+ {{ $t("AbnaYousef.project_info.project_info") }}</div>
            <br>
            {{ $t("AbnaYousef.project_info.work.1") }} /
            <br class="hidden lg:block">
            {{ $t("AbnaYousef.project_info.work.2") }} /
            <br class="hidden lg:block">
            {{ $t("AbnaYousef.project_info.work.3") }} /
            </div>
          <div class="lg:w-9/12 text-white mt-6">
            <p class="text-4xl fontBold ">{{ $t("AbnaYousef.project_info.description.title") }}</p>

            <div class="">
              <p class="mt-6 md:text-justify leading-7 text-lg">
                {{ $t("AbnaYousef.project_info.description.content_1") }}
              </p>

              <p class="mt-6 md:text-justify leading-7 text-lg">
                 {{ $t("AbnaYousef.project_info.description.content_2") }}
              </p>

            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="hero" class="w-full h-64 md:h-auto lg:h-screen relative bg-center">

      <div class="flex justify-center items-center h-full bg-AbnaYousef relative">
        <img src="../assets/images/abnayousef/01-LANDING.jpg" alt="AbnaYousef" class="w-full h-full">
      </div>
    </section>

    <section class="background_abnayousef bg-right bg-no-repeat bg-clip-text lg:bg-clip-content lg:bg-fixed ">
      <div class="max-w-6xl mx-auto px-4 xl:px-0 z-20" :class="`${$i18n.locale}` == 'ar' ? 'flex justify-end' : ''">
        <div class="lg:w-9/12 py-8 md:py-20 max-w-xl">
          <p class="text-lg md:text-5xl lg:text-6xl fontBold ">{{ $t("AbnaYousef.1.title") }}</p>
          <p class="text-sm md:text-base  md:text-justify  mt-4">{{ $t("AbnaYousef.1.description") }}</p>
        </div>
      </div>
    </section>
      
    <section id="The_present_past_1" class="bg-AbnaYousef-bg">
      <div class="max-w-8xl mx-auto py-4 md:py-12 lg:py-20 px-6 md:px-8 lg:px-12 xl:px-0">
        <img src="../assets/images/abnayousef/02-MOODBOARD.jpg" alt="">
      </div>
    </section>

    <section id="concept" class="concept_abnayousef bg-fixed">
      <div class="max-w-2xl mx-auto py-4 md:py-12 lg:py-20 px-6 md:px-8 lg:px-12 xl:px-0 pb-16">
        
        <img src="../assets/images/abnayousef/03-LOGO.svg" alt="" class="max-w-xs md:max-w-lg mx-auto">

        <div class="text-center mt-16 md:mt-48 text-white">
          <p class="text-lg md:text-5xl fontBold md:tracking-ls">{{ $t("AbnaYousef.2.title") }}</p>
          <p class="text-sm md:text-base mt-4">{{ $t("AbnaYousef.2.description-1") }}</p>
          </div>

        <img src="../assets/images/abnayousef/02-BRANDMARK.svg" alt="" class="max-w-xs md:max-w-lg mx-auto mt-16 md:mt-32">
      </div>
    </section>

    <section id="basic_needs" class="flex items-center max-w-8xl mx-auto px-6 md:px-8 lg:px-12 xl:px-0 py-6 md:py-0 ">
      <div class="md:w-3/5">
          <p class="text-lg md:text-5xl lg:text-6xl fontBold ">{{ $t("AbnaYousef.3.title") }}</p>
          <p class="text-sm md:text-base md:text-justify mt-4">{{ $t("AbnaYousef.3.description") }}</p>
      </div>
      <div class="md:w-2/5 hidden md:flex justify-center">
        <img src="../assets/images/abnayousef/01-DROP.png" alt="" class=" h-80">
      </div>
    </section>

    <section id="The_present_past_1" class="bg-AbnaYousef-bg">
      <div class="max-w-8xl mx-auto py-4 md:py-12 lg:py-20 px-6 md:px-8 lg:px-12 xl:px-0">
        <img src="../assets/images/abnayousef/04-PATTERNS.svg" alt="">
      </div>
    </section>

    <section class=" ">
      <div class="PATTERN_SIDE_abnayousef bg-right bg-no-repeat bg-clip-text lg:bg-clip-content lg:bg-fixed">
        <div class="max-w-6xl mx-auto px-4 xl:px-0 z-20" :class="`${$i18n.locale}` == 'ar' ? 'flex justify-end' : ''">
          <div class="lg:w-9/12 py-8 md:py-20 max-w-xl">
            <p class="text-lg md:text-5xl lg:text-6xl fontBold ">{{ $t("AbnaYousef.4.title") }}</p>
            <p class="text-sm md:text-base md:text-justify mt-4">{{ $t("AbnaYousef.4.description-1") }}</p>
          </div>
        </div>
      </div>

      <div class="max-w-4xl mx-auto ">
        <img src="../assets/images/abnayousef/02-LOGO_TRIALS.png" alt="">
      </div>

      <div class="max-w-5xl mx-auto px-4 xl:px-0 text-center">
          <p class="text-sm md:text-base   mt-4 justify-center">{{ $t("AbnaYousef.4.description-2") }}</p>
      </div>
    </section>

    <section class="max-w-8xl mx-auto px-6 md:px-8 lg:px-12 xl:px-0 mb-12 md:mb-24">
      <img src="../assets/images/abnayousef/04-STATIONARY.jpg" alt="" class="mt-6">
      <img src="../assets/images/abnayousef/05-MARKER.jpg" alt="" class="mt-6">
      <img src="../assets/images/abnayousef/06-BOTTLE.jpg" alt="" class="mt-6">
    </section>

    <div class="text-xl md:text-3xl flex justify-center text-white bg-footer pt-4 linkwork fontRg -mt-1 -mb-1 z-10">
      <router-link :to="`/${$i18n.locale}/anharAlkhair`">
        {{ $t("Next-Project") }}
      </router-link>
    </div>
   
  </div>
</template>

<script>
export default {
  data() {
      return {
        info : false
      }
  },
  mounted() {
    scrollTo(0, 0)
  },
  methods:{
    toggle(){
      if(this.info == true){
        this.info = false
        document.body.style.overflow = ""
      }else{
        this.info = true
        scrollTo(0, 0)
        document.body.style.overflow = "hidden"
      }
      
    }
  }
}
</script>

<style>
  .background_abnayousef{
    background-image: url('../assets/images/abnayousef/01-MARK-HALF.svg')
  }

  .concept_abnayousef{
    background-image: url('../assets/images/abnayousef/03-BG.jpg')
  }

  .PATTERN_SIDE_abnayousef{
    background-image: url('../assets/images/abnayousef/05-PATTERN_SIDE.svg')
  }
</style>